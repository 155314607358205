<template>
    <div class="container py-5">
        <div class="alert alert-warning">
            <p>
                {{ $t('conflictSession.message') }}
            </p>
            <p>
                <b-btn
                    variant="primary"
                    size="sm"
                    class="mr-3"
                    @click.prevent="onContinueWithNew"
                >
                    {{ $t('conflictSession.continueWithNew') }}
                </b-btn>
                <b-btn
                    variant="primary"
                    size="sm"
                    @click.prevent="onContinueWithOld"
                >
                    {{ $t('conflictSession.continueWithOld') }}
                </b-btn>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConflictSession',
    methods: {
        onContinueWithNew () {
            window.location = '/discard-old-session'
        },
        onContinueWithOld () {
            window.location = '/continue-old-session'
        }
    }
}
</script>
